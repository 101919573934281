import { BUGSNAG_API_KEY } from '@@/bugsnag-api-key'
import type { Client } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BRAND } from '@/config/brand'
import { nextConfig } from '@/helpers/env'

// These domains clutter the breadcrumbs data which makes it hard to debug video player errors
const excludedBreadcrumbDomains = [
  'fwmrm.net',
  'tagging-service.tv4play.se',
  'tagging-service.mtv.fi',
  'youboranqs01.com',
  'k5a.io',
  'vod.streaming.a2d.tv',
]

const getInstance = () => {
  let client: Client

  const enabled = () => nextConfig.bool('ENABLE_THIRD_PARTY_SERVICES') && !!nextConfig.string('BUGSNAG_APP_VERSION')

  const stages = () => {
    let stages = ['production', 'stage']
    if (nextConfig.bool('BUGSNAG_REPORT_DEV_STAGE')) {
      stages = [...stages, 'development']
    }

    return stages
  }

  return () => {
    if (enabled() && !client) {
      client = Bugsnag.start({
        apiKey: BUGSNAG_API_KEY,
        appVersion: nextConfig.string('BUGSNAG_APP_VERSION'),
        collectUserIp: false,
        releaseStage: nextConfig.string('BUGSNAG_RELEASE_STAGE'),
        enabledReleaseStages: stages(),
        autoDetectErrors: false,
        plugins: [new BugsnagPluginReact()],
        metadata: {
          app: {
            brand: BRAND,
          },
        },
        onBreadcrumb: function (breadcrumb) {
          if (breadcrumb.type === 'request') {
            const url = breadcrumb?.metadata?.request
            if (url && excludedBreadcrumbDomains.some((domain) => url.includes(domain))) {
              return false
            }
          }
          return true
        },
      })
    }

    return client
  }
}

export const getClient = getInstance()
