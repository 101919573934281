import packageJson from '@@/package.json'
import { HttpLink, from, split } from '@apollo/client'
import { ApolloClient, ApolloLink, Observable } from '@apollo/client/core'
import type { NormalizedCacheObject } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import Bugsnag from '@bugsnag/js'
import { sha256 } from 'crypto-hash'
import { v4 as uuidv4 } from 'uuid'
import { createInMemoryCache } from '@nordic-web/gql/src/apollo-in-memory-cache'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { isValidTierValue } from '@/components/tier-override-select'
import { brandConfig } from '@/config/brand'
import { authenticationStore } from '@/features/auth/authentication-store'
import { nextConfig } from '@/helpers/env'

let apolloClient: ApolloClient<NormalizedCacheObject> | null

// Used to keep track of the shorts algorithm session
export const sessionId = uuidv4()

const GRAPHQL_URL = nextConfig.string('GRAPHQL_URL')
// This is nice when testing to see what query is actually requested in dev tools
const shouldDisablePersistedQueries = nextConfig.bool('DISABLE_PERSISTED_QUERIES')

const tierOverrideLink = setContext((_, { headers }) => {
  if (isClientSide) {
    const tierOverride = authenticationStore.getSnapshot().tierOverrideId

    if (isValidTierValue(tierOverride)) {
      return {
        headers: {
          'tier-override-id': tierOverride,
          ...headers,
        },
      }
    }
  }

  return { headers }
})

const correlationIdLink = setContext((_, { headers }) => {
  return {
    headers: {
      'x-correlation-id': uuidv4(),
      ...headers,
    },
  }
})

// This link uses the observable approach to be able to cancel the request if the access token request fails.
// When that request fails, we dont want to continue the request and cause a 401 reply from graphql
const authLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    authenticationStore
      .getValidAccessToken()
      .then((accessToken) => {
        const headers = operation.getContext().headers || {}

        if (accessToken) {
          const entitlements = parseJwt(accessToken)?.entitlements
          operation.setContext({
            headers: {
              ...headers,
              authorization: formatAuthorizationHeader(accessToken),
              ...(entitlements ? { 'user-entitlements': entitlements?.join(',') } : {}),
            },
          })
        } else {
          operation.setContext({ headers })
        }

        const subscriber = forward(operation).subscribe({
          next: (result) => observer.next(result),
          error: (error) => observer.error(error),
          complete: () => observer.complete(),
        })

        return () => {
          if (subscriber) subscriber.unsubscribe()
        }
      })
      .catch((error) => {
        observer.error(error)
      })
  })
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    if ('statusCode' in networkError && networkError.statusCode !== undefined) {
      Bugsnag.notify('A network error occured when calling the graphql API', (event) => {
        event.addMetadata('details', {
          errorCode: networkError.statusCode,
          rawError: networkError,
        })
      })
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      Bugsnag.notify('A graphql error was returned from the graphql API', (event) => {
        event.addMetadata('details', {
          rawError: error,
        })
      })
    })
  }
})

const featureFlagHeaders = {
  'feature-toggle-include-sportevents-in-endscreen-recommendations': 'true',
  'feature-toggle-include-multi-single-panel': 'true',
}

const commonHttpOptions = () => ({
  uri: GRAPHQL_URL + '/graphql',
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  headers: {
    'client-name': brandConfig.clientName,
    'client-version': packageJson.version,
    'session-id': sessionId,
    ...featureFlagHeaders,
  },
  fetch,
})

const batchLink = () =>
  new BatchHttpLink({
    ...commonHttpOptions(),
    batchMax: 20, // a max number of items to batch, defaults at 10
  })

const persistedQueryhttpLink = () => {
  const httpLink = new HttpLink(commonHttpOptions())

  if (shouldDisablePersistedQueries) {
    return from([correlationIdLink, httpLink])
  }

  return from([correlationIdLink, createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }), httpLink])
}

type SafeApolloClient = ApolloClient<NormalizedCacheObject> & {
  toJSON?: () => null
}

function create(stateFromServer?: NormalizedCacheObject) {
  const client: SafeApolloClient = new ApolloClient({
    connectToDevTools: isClientSide,
    ssrMode: !isClientSide, // Disables forceFetch on the server (so queries are only run once)
    link: from([
      errorLink,
      tierOverrideLink,
      authLink,
      split((operation) => operation.getContext().batch, batchLink(), persistedQueryhttpLink()),
    ]),
    cache: createInMemoryCache().restore(stateFromServer || {}),
  })

  // See this issue for more info: https://github.com/vercel/next.js/issues/9336#issuecomment-1092830219
  client.toJSON = () => null

  return client
}

export function initApollo(stateFromServer?: NormalizedCacheObject) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isClientSide) {
    return create(stateFromServer)
  }
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(stateFromServer)
  }

  return apolloClient
}
